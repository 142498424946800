import React from "react"

interface Props {
  children: React.ReactNode
}

export default (props: Props) => {
  const childrenCount = React.Children.count(props.children)
  const items = React.Children.map(props.children, (child, i) => {
    const isLastChild = (i === childrenCount - 1)
    const className = isLastChild ? "is-active" : ""
    return (
      <li key={i} className={className}>{child}</li>
    )
  })

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        {items}
      </ul>
    </nav>
  )
}
