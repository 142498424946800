import React from "react"
import styled from "styled-components"

interface Props {
  imageName: string
}

export default (props: Props) => {
  return (
    <Container>
      <Image url={`/images/gyms/${props.imageName}`} />
    </Container>
  )
}

const Container = styled.div`
  max-height: 250px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Image = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (16 / 9));

  background-image: url(${(props: {url: string}) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
